<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="edit" name="plantCd" :disabled="plantDisabled" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="areaTable"
      title="공사구역 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="single"
      rowKey="pjmProjectAreaId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'project-area-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
        processLevelCd: '20',
        plantCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function () {
        return 'auto';
      },
    },
  },
  data() {
    return {
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'pjmProjectAreaName',
            field: 'pjmProjectAreaName',
            label: '공사구역',
            align: 'center',
            sortable: true,
          },
          {
            name: 'projectRoleUserName',
            field: 'projectRoleUserName',
            label: '공사구역 총괄',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '100%',
      },
      searchParam: {
        plantCd: null,
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    plantDisabled() {
      return Boolean(this.popupParam.plantCd)
    },
  },
  watch: {
    contentHeight() {
      let height =
        this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50;
      if (height < 400) {
        height = 500;
      }

      this.grid.height = height + 'px';
    },
  },
  methods: {
    init() {
      this.listUrl = selectConfig.pjm.area.list.plant;
      
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.param = this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      });
    },
    select() {
      let selectData = this.$refs['areaTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공사구역을 선택하세요.', // 공사구역을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  },
};
</script>
